import CoursesTable, { RowDataT } from './CoursesTable'

export default function CoursesTableBusiness({
  goToCourse,
  data,
  onFetchMoreData,
  loading,
  pageCount,
  pageSize,
  setPageSize,
  deleteCourse,
  sendMailing,
  confirmCourse,
  cancelCourse
}: {
  goToCourse: (id: string | null) => void
  data: RowDataT[]
  onFetchMoreData: () => void
  loading: boolean
  pageCount: number
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  deleteCourse?: (id: string) => void
  sendMailing: (id: string) => void
  confirmCourse: (id: string) => void
  cancelCourse: (id: string) => void
}) {

  return (
    <CoursesTable
      goToCourse={goToCourse}
      data={data}
      onFetchMoreData={onFetchMoreData}
      loading={loading}
      pageCount={pageCount}
      pageSizeInput={pageSize}
      setPageSizeInput={setPageSize}
      removeRow={deleteCourse}
      sendMailing={sendMailing}
      confirmCourse={confirmCourse}
      cancelCourse={cancelCourse}
    />
  )
}
