import React, { useMemo, useEffect, useState, ReactElement } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useStyletron } from 'baseui'
import { useTable, usePagination } from 'react-table'
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Button, SIZE, KIND } from 'baseui/button'
import { Check, Delete, Menu, Show } from 'baseui/icon'
import { Tag, VARIANT } from 'baseui/tag'
import { Select } from 'baseui/select'

import SimpleModal from '@lemonbrain/components/UiElements/SimpleModal/SimpleModal'
// import ConfirmRegistrationButton from '../CoursesRegistrations/ConfirmRegistrationButton/ConfirmRegistrationButton'
// import SendMailingIcon from '../../../icons/SendMailingIcon'
import { CourseFragmentFragment } from '../../../gql/Courses.generated'
import ParticipantsExportFile from './ParticipantsExport/ParticipantsExport.file'
import ExportAttendeesListData from './ExportAttendeesList/ExportAttendeesList.data'

export type RowDataT = {
  id: string
  title: string
  date: string
  status: ReactElement
  hasOpenRegistration: boolean
  hasWaitingList: boolean
  hasMailableRegistration: boolean
  createdFrom: string,
  item: CourseFragmentFragment,
  courseStatus: ReactElement,
  courseStatusValue: string,
}

export default function CoursesTable({
  goToCourse,
  data,
  onFetchMoreData,
  loading,
  pageCount: controlledPageCount,
  pageSizeInput,
  setPageSizeInput,
  removeRow,
  //@ts-ignore
  sendMailing,
  confirmCourse,
  cancelCourse,
}: {
  goToCourse: (id: string | null, viewRegistrations?: boolean) => void
  data: RowDataT[]
  onFetchMoreData: () => void
  loading: boolean
  pageCount: number
  pageSizeInput: number
  setPageSizeInput: React.Dispatch<React.SetStateAction<number>>
  removeRow?: (id: string) => void
  sendMailing: (id: string) => void
  confirmCourse: (id: string) => void
  cancelCourse: (id: string) => void
}) {
  const intl = useIntl()
  const [css] = useStyletron()
  const [deleteId, setDeleteId] = useState<string | undefined>()
  const [confirmId, setConfirmId] = useState<string | undefined>()
  const [cancelId, setCancelId] = useState<string | undefined>()

  const columns = useMemo(() => {
    const columns = [
      {
        Header: intl.formatMessage({
          id: 'courses.coursesTable.number',
          defaultMessage: 'Number',
        }),
        accessor: 'number', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'courses.coursesTable.course',
          defaultMessage: 'Course',
        }),
        accessor: 'title', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'courses.coursesTable.courseStatus',
          defaultMessage: 'Course Status',
        }),
        accessor: 'courseStatus', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'courses.coursesTable.date',
          defaultMessage: 'Date',
        }),
        accessor: 'date', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({
          id: 'courses.coursesTable.status',
          defaultMessage: 'Registration Status',
        }),
        accessor: 'status', // accessor is the "key" in the data
      },
    ]

    return columns
  }, [intl])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
     //@ts-ignore
    page,
     //@ts-ignore
    canNextPage,
     //@ts-ignore
    nextPage,
     //@ts-ignore
    setPageSize,
     //@ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
       //@ts-ignore
      columns,
      data,
      initialState: {
         //@ts-ignore
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      pageSize: pageSizeInput,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'actions',
          Header: () => (
            <div>
              <FormattedMessage
                id='courses.coursesTable.actions'
                defaultMessage={'Actions'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <>
              <Button
                onClick={() => goToCourse(row.original.id)}
                size={SIZE.compact}
                kind={KIND.secondary}
              >
                <Show />
              </Button>
              <Button
                onClick={() => setDeleteId(row.original.id)}
                size={SIZE.compact}
                kind={KIND.secondary}
              >
                <Delete />
              </Button>
            </>
          ),
        },
        {
          id: 'courseActions',
          Header: () => (
            <div>
              <FormattedMessage
                id='courses.coursesTable.courseActions'
                defaultMessage={'Course Actions'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <>
              <Button
                onClick={() => {setConfirmId(row.original.id)}}
                size={SIZE.compact}
                kind={KIND.secondary}
                disabled={row.original.courseStatusValue !== 'open'}
                colors={{ backgroundColor: 'darkgreen', color: 'white'}}
              >
                <Check />
              </Button>
              <Button
                onClick={() => {setCancelId(row.original.id)}}
                size={SIZE.compact}
                kind={KIND.secondary}
                disabled={row.original.courseStatusValue !== 'open'}
                colors={{ backgroundColor: 'darkred', color: 'white'}}
              >
                <Delete />
              </Button>
            </>
          ),
        },
        ...columns,
        {
          id: 'registrations',
          Header: () => (
            <div>
              <FormattedMessage
                id='courses.coursesTable.registrations'
                defaultMessage={'Registrations'}
              />
            </div>
          ),
          //@ts-ignore
          Cell: ({ row }) => (
            <>
              <Button
                onClick={() => {goToCourse(row.original.id, true)}}
                size={SIZE.compact}
                kind={KIND.secondary}
              >
                <Show />
                <Menu
                  title={intl.formatMessage({
                    id: 'courses.coursesTable.registrations',
                    defaultMessage: 'course registrations',
                  })}
                />
              </Button>
              {' '}
              {/* <ConfirmRegistrationButton
                icon={<Check
                  title={intl.formatMessage({
                    id: 'courses.coursesTable.status.confirmPending',
                    defaultMessage: 'confirm pending',
                  })}
                />}
                confirmRegistration={confirmOpenRegistrations}
                itemId={row.original.id}
                disabled={!row.original.hasOpenRegistration}
                primary={true}
              />
              <ConfirmRegistrationButton
                icon={<Plus
                  title={intl.formatMessage({
                    id: 'courses.coursesTable.status.confirmWaitingList',
                    defaultMessage: 'confirm waiting list',
                  })}
                />}
                confirmRegistration={confirmWaitingList}
                itemId={row.original.id}
                disabled={!row.original.hasWaitingList}
                primary={true}
              />
              <Button
                onClick={() => {sendCourseMailings(row.original.item)}}
                size={SIZE.compact}
                kind={KIND.secondary}
                disabled={!row.original.hasMailableRegistration}
              >
                <SendMailingIcon disabled={!row.original.hasMailableRegistration}/>
              </Button> */}
              <ParticipantsExportFile courseId={row.original.id} />
              <ExportAttendeesListData courseId={row.original.id} />
            </>
          ),
        },
      ])
    }
  )

  useEffect(() => {
    if (pageSize !== pageSizeInput) {
      setPageSize(pageSizeInput)
    } else {
      if (pageIndex !== controlledPageCount) {
        onFetchMoreData()
      }
    }
  }, [pageIndex, pageSizeInput])

  return (
    <>
      {/* delete modal */}
      <SimpleModal<string>
        isOpen={!!deleteId}
        onClose={() => setDeleteId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'courses.coursesTable.delete',
          defaultMessage: 'Delete',
        })}
        content={intl.formatMessage({
          id: 'courses.coursesTable.deleteConfirm',
          defaultMessage: 'Are you sure to delete this course?',
        })}
        data={deleteId || ''}
        onConfirm={(deleteId: string) => {
          removeRow && removeRow(deleteId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'courses.coursesTable.delete',
          defaultMessage: 'Delete',
        })}
      ></SimpleModal>
      {/* confrim modal */}
      <SimpleModal<string>
        isOpen={!!confirmId}
        onClose={() => setConfirmId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'courses.coursesTable.confirm',
          defaultMessage: 'Confirm',
        })}
        content={intl.formatMessage({
          id: 'courses.coursesTable.confirmConfirm',
          defaultMessage: 'Are you sure to confirm this course?',
        })}
        data={confirmId || ''}
        onConfirm={(confirmId: string) => {
          confirmCourse(confirmId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'courses.coursesTable.confirm',
          defaultMessage: 'Confirm',
        })}
      ></SimpleModal>
      {/* cancel modal */}
      <SimpleModal<string>
        isOpen={!!cancelId}
        onClose={() => setCancelId(undefined)}
        headerLabel={intl.formatMessage({
          id: 'courses.coursesTable.cancel',
          defaultMessage: 'Cancel',
        })}
        content={intl.formatMessage({
          id: 'courses.coursesTable.cancelConfirm',
          defaultMessage: 'Are you sure to cancel this course?',
        })}
        data={cancelId || ''}
        onConfirm={(cancelId: string) => {
          cancelCourse(cancelId)
        }}
        confirmLabel={intl.formatMessage({
          id: 'courses.coursesTable.cancel',
          defaultMessage: 'Cancel',
        })}
      ></SimpleModal>

      <StyledTable
        role='grid'
        // $gridTemplateColumns={`repeat(${columns.length + 2},1fr)`}
        $gridTemplateColumns={`1fr 1fr 1fr 4fr 1fr 1fr 1fr 2fr`}
        {...getTableProps()}
      >
        {headerGroups.map((headerGroup, i) => (
          <React.Fragment key={i}>
            {
          //@ts-ignore
          headerGroup.headers.map((column, j) => (
              <StyledHeadCell {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                  { //@ts-ignore
                  column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </StyledHeadCell>
            ))}
          </React.Fragment>
        ))}
        { //@ts-ignore
        page.map((row, i) => {
          prepareRow(row)
          return (
            <React.Fragment key={i}>
              { //@ts-ignore
              row.cells.map((cell, j) => {
                return (
                  <StyledBodyCell
                    key={i.toString() + j.toString()}
                    {...cell.getCellProps()}
                    $striped={true}
                  >
                    {cell.render('Cell')}
                  </StyledBodyCell>
                )
              })}
            </React.Fragment>
          )
        })}
        {loading ? (
          // Use our custom loading state to show a loading indicator
           //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='courses.coursesTable.loading'
              defaultMessage={'Loading...'}
            />
          </StyledBodyCell>
        ) : ( //@ts-ignore
          <StyledBodyCell colSpan='10000'>
            <FormattedMessage
              id='courses.coursesTable.results_count'
              defaultMessage='Showing {pageLength} results'
              values={{
                pageLength: page.length,
              }}
            />
          </StyledBodyCell>
        )}
      </StyledTable>
      <div
        className={css({
          paddingTop: '1rem',
        })}
      >
        <Grid align={ALIGNMENT.center}>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                <FormattedMessage
                  id='courses.coursesTable.load_mores'
                  defaultMessage={'Load mores'}
                />
              </Button>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Tag closeable={false} variant={VARIANT.light} kind='neutral'>
                <FormattedMessage
                  id='courses.coursesTable.page_count'
                  defaultMessage='Page {currentPage}'
                  values={{
                    currentPage: pageIndex + 1,
                  }}
                />
              </Tag>
            </div>
          </Cell>
          <Cell span={[12, 4, 4]}>
            <div
              className={css({
                textAlign: 'center',
              })}
            >
              <Select
                clearable={false}
                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                  id: intl.formatMessage(
                    {
                      id: 'courses.coursesTable.page_size_selection',
                      defaultMessage: 'Show {pageSize} Items per Page',
                    },
                    {
                      pageSize,
                    }
                  ),
                  pageSize,
                }))}
                labelKey='id'
                valueKey='pageSize'
                onChange={({ value }) => {
                  setPageSizeInput(Number(value[0].pageSize))
                }}
                value={[{ id: pageSize.toString(), pageSize }]}
              />
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  )
}
